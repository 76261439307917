<template>
    <div id="order-container">
        <div class="order-content">
            
                 <el-table
                    :data="orderDataList"
                    style="width: 100%">
                    <el-table-column
                    label="订单编码"
                    prop="number">
                    </el-table-column>
                    <el-table-column
                    label="支付时间"
                    prop="time">
                    </el-table-column>
                    <el-table-column
                    label="订单Id"
                    prop="id">
                    </el-table-column>
                    <el-table-column
                    label="下单时间"
                    prop="paymentMethod">
                    </el-table-column>
                    <el-table-column
                    label="订单状态"
                    prop="status">
                    </el-table-column>
                    <el-table-column
                    label="价格"
                    prop="totalPrice">
                    </el-table-column>
                    <el-table-column
                    label="物流状态"
                    prop="financialStatus">
                    </el-table-column>
                    <el-table-column
                    label="货币"
                    prop="currency">
                    </el-table-column>
                </el-table>
        </div> 
    </div>
</template>

<script>
import {mapActions , mapGetters} from 'vuex'
import {getOrderDetail,getOrder} from '@/api/mall/index.js'
export default {
    data(){
        return {
            page:1,
            size:50,
            orderDataList:[]
        }
    },
    computed:{
        ...mapGetters([
            'userId'
        ])
    },
    mounted(){
        this.initOrder()
    },
    methods: {
        initOrder(){
            let data = {
                customerId:this.userId,
                page:this.page,
                size:this.size
            }
            getOrder(JSON.stringify(data)).then(res=>{
                let list = res.data.result.resultList
                
                if(list.length>0){
                    this.orderDataList = list.map((item)=>{
                        item.time = new Date(item.createAt).toLocaleDateString()
                        return item
                    })
                    console.log(list)
                    console.log(this.orderDataList)
                }else{
                    this.orderDataList = []
                }
                
            })
        }
    },
}
</script>

<style lang="stylus" scoped>
div#order-container
    background #fff
    color #222
    height 100%
    border-top 1px solid #a0a0a0
    padding 2.5rem 13.75%
</style>
